<template>
  <div class="header" v-if="title || subtitle || paragraph">
    <h1 class="title" v-if="title">{{ title }}</h1>
    <h3 class="subtitle" v-if="subtitle">{{ subtitle }}</h3>
    <div v-if="paragraph">{{ paragraph }}</div>
  </div>

  <div class="grid justify-content-center">
    <div class="grid-row">
      <div>
        <div class="flex-container valueCardsContainer">
          <div class="grid-row">
            <div class="grid-cell" v-for="card in cards">
              <div class="grid-content">
                <img :src="card.imgSrc" />
                <h3 class="cardHeader">{{ card.title }}</h3>
                <div class="subtitle2">{{ card.paragraph }}</div>
                <div class="list-wrapper">
                  <ul v-for="item in card.listContent">
                    <li>
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="award-container">
    <h3 class="award-header">{{ awardHeader }}</h3>
    <div class="grid-2">
      <div class="flex-3 recognition-card" v-for="image in images">
        <img :src="image.imgSrc" class="mini-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "cards",
    "title",
    "subtitle",
    "awardHeader",
    "paragraph",
    "imgSource",
    "images",
    "imgSrc",
    "listContent",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.recognition-card {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 48px;
  @include color(takeda-dark-maroon);
  max-width: 100%;
}

.subtitle {
  padding-bottom: 10px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
  padding-left: 2em;
  padding-right: 2em;
  @include responsive(phone) {
    padding-left: 2em;
    padding-right: 2em;
  }
  @include responsive(tablet) {
    display: flex;
    justify-content: center;
  }
  .grid-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.grid-2 {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.mini-img {
  padding-top: 50px;
  height: 100px;
}

.award-header {
  @include padding-left(8);
}

.grid-cell {
  @include bg-color(takeda-light-grey);
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
  overflow: hidden;
  position: relative;
  margin: 15px;
  padding: 20px;
  width: 195px;
  @include responsive(phone) {
    width: 100%;
    margin: 15px 0px;
  }
}

.grid-content {
  padding-top: 10px;
  padding-bottom: 20px;
}

.cardHeader {
  height: 50px;
}
.valueCardsContainer {
  max-width: 1060px;
}

ul {
  font-family: "Slalom Sans";
  font-size: 14px;
  font-weight: 300;
  padding-left: 25px;
  margin: 0;
}

.list-wrapper {
  margin-top: 10px;
}
</style>