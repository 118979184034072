<template>
  <div class="header-section" v-if="title||subtitle||paragraph">
    <div>
      <h1 class="title" v-if="title">{{ title }}</h1>
    </div>
    <div>
      <h1 class="subtitle" v-if="subtitle">{{ subtitle }}</h1>
    </div>
    <div>
      <div class="paragraph" v-if="paragraph">{{ paragraph }}</div>
    </div>
  </div>
  
  <div class="grid-wrapper">
  <div class="grid" v-if="cards">
    <div class="grid-cell" v-for="card in cards">
      <img :src="card.imgSrc" class="mini-img" />
      <h4 class="tileTitle">{{ card.tileTitle }}</h4>
      <div>{{ card.tileParagraph }}</div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: [
    "cards",
    "title",
    "subtitle",
    "paragraph",
    "imgSrc",
    "tileTitle",
    "tileParagraph",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.header-section  {
  .paragraph {
    padding: 0 75px;
    @include responsive(phone){
      padding: 0;
    }
  }
    .title {
      max-width: 100%;
    }
}

.grid-wrapper {
  padding: 0 4em;
  display: flex;
  justify-content: center;
  @include responsive(phone) {
   display: block;
   padding: 0 1em;
  }
  @include responsive(tablet) {
   display: block;
  }
}

.title {
  padding-bottom: 30px;
  font-size: 48px;
  @include color(takeda-dark-maroon);

}

.paragraph {
  padding: 0 75px;
}

.grid {
  display: flex;
  justify-content: center;
 @include responsive(phone) {
   flex-direction: column ;
  }
  @include responsive(tablet) {
    flex-direction: column;
  }

}


.grid-cell {
  @include padding(0 4);

  @include responsive(phone) {
    flex: 1 0 100%;

    @include padding(2 0);
  }
  @include responsive(tablet) {
    flex: 0 0 100%;
     @include padding(2 0)
  }
}
</style>