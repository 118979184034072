<template>
  <div class="flex-container rectangleCard-container">
    <div class="flex-6 img-container" v-if="flipImage">
      <img :src="imgSrc" alt="Slalom White Logo" class="card-image" />
    </div>
    <div class="flex-6 content">
      <h1>{{ title }}</h1>
      <p v-html="content" />
      <div v-for="item in optionalContent">
        <p class="body-text"> {{item}} </p>
      </div>
      <div v-for="item in linkList">
        <a style="text-decoration: none; color: inherit;" :href="item.link" target="_blank">
            Learn More
            <img class ="arrow" src='@/assets/images/next-arrow.svg' />
        </a>
      </div>
      <div class="link-button" v-if="buttonLink">
        <a :href="buttonLink.link" target="_blank">
            {{buttonLink.text}}
        </a>
      </div>
    </div>
    <div class="flex-6 img-container" v-if="!flipImage" >
      <img :src="imgSrc" alt="Slalom White Logo" class="card-image"/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "title",
    "content",
    "optionalContent",
    "linkList",
    "imgSrc",
    "flipImage",
    "buttonLink",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

h1 {
  @include color(takeda-light-pink)
}

p {
  font-weight: 400;
  color: $font-color-1;
  :deep(b){
    font-weight:600;
  }
}

a {
  display: flex;
  text-align: center;
  @include color(takeda-dark-grey);
  font-weight: 600;
  font-size: 18px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @include margin(-20 0 -20 0);
  @include responsive(phone) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
  }
  @include responsive(tablet) {
    @include margin(0);
    min-width: 0;
    min-height: 0;
    max-width: 100%;
  }
}

.card-image {
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
   box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  @include responsive(phone) {
    position:static;
    width:100%;
  }
  @include responsive(tablet) {
    position:static;
  }
}

.content {
    @include responsive(phone) {
    @include padding(0 4 0 4);
  }
  @include responsive(tablet) {
    @include padding(0);
  }
}

.arrow {
  margin-left: 10px;
}

.rectangleCard-container {
  @include bg-color(takeda-light-grey);
  @include padding(8);
  border-bottom-right-radius: 4em;
  position: relative;

  @include responsive(phone) {
    @include margin(1 1 1 1);
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include margin(5 5 5 5);
    @include padding(5 5 5 5);
  }
}

.link-button {
  @include animate;
  @include padding(1 3 1 3);
  display: inline-block;
  border-radius: 4px 4px 16px 4px;
  border: 2px solid #FFFFFF; 
  &:hover {
    @include opacity(0.7);
  }
  & a {
    text-decoration: none; 
    font-weight:500; 
    font-size: 18px; 
  }
}

</style>
