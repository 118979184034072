<template>
    <div class= "navigator-container">
        <router-link style="text-decoration: none; color: inherit;" :to="previous.route">
        <img src='@/assets/images/previous-arrow.svg' />
            {{previous.name}}
        </router-link>
        <div class="divider"> </div>
        <router-link style="text-decoration: none; color: inherit;" :to="next.route">
            {{next.name}}
            <img src='@/assets/images/next-arrow.svg' />
        </router-link>
    </div>
</template>

<script>
import * as constants from "@/components/constants.js";

export default {
  props: ["order"],
   data() {
    const previous = constants.PageOrder[this.order - 1];
    const next = constants.PageOrder[(this.order + 1) % constants.PageOrder.length]
    return {
      previous,
      next,
    };
  },
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.navigator-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 65px;
  font-weight: 600;  
  @include responsive(phone) {
   column-gap: 10px;
   font-size: 12px;
  }
}

img {
    @include padding(0 2 0 2);
}

a {
  display: flex;
  text-align: center;
  @include color(takeda-dark-grey);
}

.divider {
    @include bg-color(takeda-medium-gray);
    width: 1px;
}

</style>
