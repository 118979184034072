<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle
        :title="content['page-title']"
        :imgSrc="images['header.svg']"
      ></PageTitle>
      <div class="section">
        <div class="padding-bottom">
          <SurveyResultsCard
            :title="content['section-1-title']"
            :paragraph="content['section-1-description']"
          />
        </div>
        <div>
          <SurveyResultsCard
            :cards="[
              {
                imgSrc: `${images['collab_icon.svg']}`,
                tileTitle: content['section-1-subsection-1-title-1'],
                tileParagraph: content['section-1-subsection-1-description-1'],
              },
              {
                imgSrc: `${images['innovation.svg']}`,
                tileTitle: content['section-1-subsection-1-title-2'],
                tileParagraph: content['section-1-subsection-1-description-2'],
              },

              {
                imgSrc: `${images['growthicon.svg']}`,
                tileTitle: content['section-1-subsection-1-title-3'],
                tileParagraph: content['section-1-subsection-1-description-3'],
              },
            ]"
          />
        </div>
      </div>
    </div>

    <div class="section-container">
      <h1 class="title">{{ content["section-2-title"] }}</h1>
      <h3 class="paragraph">{{ content["section-2-description"] }}</h3>
      <div class="flex-container">
        <div class="flex-6">
          <div class="subHeader">
            {{ content["section-2-card-section-1-title"] }}
          </div>
          <div class="grid measuring-card-set">
            <div class="grid-row">
              <div class="flex-6" v-for="card in expectedCards">
                <div class="card-wrapper">
                  <MeasuringCard :card="card" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-6">
          <div class="subHeader">
            {{ content["section-2-card-section-2-title"] }}
          </div>
          <div class="grid measuring-card-set">
            <div class="grid-row">
              <div class="flex-6" v-for="card in transformationCards">
                <div class="card-wrapper">
                  <MeasuringCard :card="card" />
                </div>
              </div>
              <div class="flex-12 graph-card">
                <div class="card-wrapper">
                  <div class="grid-cell">
                    <div class="grid-content">
                      <h1 class="cardHeading">
                        {{ content["section-2-card-11-title"] }}
                      </h1>
                      <div class="graph-wrapper">
                        <div class="leftSide-graph">
                          <div class="number">
                            {{ content["section-2-card-11-newvalue"]
                            }}<span
                              ><img
                                class="triangle-img"
                                src="@/assets/images/survey/triangle.png"
                                alt=""
                            /></span>
                          </div>
                          <div class="subtitle2">
                            {{ content["section-2-card-11-oldvalue"] }}
                          </div>
                          <div class="subtitleYear">2020</div>
                        </div>
                        <div class="rightSide-graph">
                          <ul style="list-style: none" class="graph">
                            <li>
                              <span class="circle circle-color-one"></span
                              ><span class="graph-rating">Great:</span
                              ><span class="percentage">52.2%</span>
                            </li>
                            <li>
                              <span class="circle circle-color-two"></span
                              ><span class="graph-rating">Good:</span
                              ><span class="percentage">47.8%</span>
                            </li>
                            <li>
                              <span class="circle circle-color-three"></span
                              ><span class="graph-rating">Fair:</span
                              ><span class="percentage">0.0%</span>
                            </li>
                            <li>
                              <span class="circle circle-color-four"></span
                              ><span class="graph-rating">Poor:</span
                              ><span class="percentage">0.0%</span>
                            </li>
                          </ul>
                          <div class="bar">
                            <div class="bar-inner blue"></div>
                            <div class="bar-inner green"></div>
                            <div class="bar-inner yellow"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <ListCard
        :titleImgSrc="require('@/assets/images/survey/friends.png')"
        :listCategory="content['section-3-title']"
        :project-goal-description="content['section-3-description']"
        linkName="Learn More"
        link="placeholder.com"
      />
    </div>

    <div class="section">
      <GuidingValueCard
        :title="content['section-4-title']"
        :subtitle="content['section-4-subsection-1-title']"
        :paragraph="content['section-4-subsection-1-description']"
      />
    </div>

    <div class="flex-container">
      <div class="flex-12">
        <GuidingValueCard
          :cards="[
            {
              imgSrc: `${images['value-1-icon.svg']}`,
              title: content['section-4-card-1-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-2-icon.svg']}`,
              title: content['section-4-card-2-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-3-icon.svg']}`,
              title: content['section-4-card-3-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-4-icon.svg']}`,
              title: content['section-4-card-4-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-5-icon.svg']}`,
              title: content['section-4-card-5-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-6-icon.svg']}`,
              title: content['section-4-card-6-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-7-icon.svg']}`,
              title: content['section-4-card-7-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-8-icon.svg']}`,
              title: content['section-4-card-8-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-9-icon.svg']}`,
              title: content['section-4-card-9-title'],
              paragraph: content['section-4-card-10-description'],
            },
            {
              imgSrc: `${images['value-10-icon.svg']}`,
              title: content['section-4-card-10-title'],
              paragraph: content['section-4-card-10-description'],
            },
          ]"
        />

        <GuidingValueCard
          :awardHeader="content['section-4-subsection-2-title']"
          :images="[
            {
              imgSrc: `${images['recognition-photo-1.png']}`,
            },
            {
              imgSrc: `${images['recognition-photo-2.png']}`,
            },
            {
              imgSrc: `${images['recognition-photo-3.png']}`,
            },
            {
              imgSrc: `${images['recognition-photo-4.png']}`,
            },
          ]"
        />
      </div>
    </div>

    <div class="section">
      <ListCard
        :titleImgSrc="require('@/assets/images/survey/image.png')"
        :listCategory="content['section-5-title']"
        :project-goal-description="content['section-5-paragraph-1']"
        :project-goal-description2="content['section-5-paragraph-2']"
        linkName="Learn More"
        link="placeholder.com"
      />
    </div>
    <BottomNavigator :order="5" />
  </Loading>
</template>

<script>
import api from "@/services/api.js";
import BottomNavigator from "@/components/BottomNavigator.vue";
import Navigation from "@/components/Navigation.vue";
import Card from "@/components/Card.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurveyResultsCard from "@/components/SurveyResultsCard.vue";
import MeasuringCard from "@/components/MeasuringCard.vue";
import GuidingValueCard from "@/components/GuidingValueCard.vue";
import ListCard from "@/components/ListCard.vue";
import Alert from "@/components/Alert.vue";

export default {
  data: () => ({
    expectedCards: [],
    transformationCards: [],
    content: {},
    images: {},
    isLoading: false,
    isError: false,
    errorMessage: "",
  }),
  methods: {
    async getData() {
      // this.headerImg = '';
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getContent("survey-results");
        this.content = data.content;
        this.expectedCards = [
          {
            title: this.content["section-2-card-1-title"],
            subtitle: this.content["section-2-card-1-newvalue"],
            subtitle2: this.content["section-2-card-1-oldvalue"],
          },
          {
            title: this.content["section-2-card-2-title"],
            subtitle: this.content["section-2-card-1-newvalue"],
            subtitle2: this.content["section-2-card-2-oldvalue"],
          },
          {
            title: this.content["section-2-card-3-title"],
            subtitle: this.content["section-2-card-1-newvalue"],
            subtitle2: this.content["section-2-card-3-oldvalue"],
          },
          {
            title: this.content["section-2-card-4-title"],
            subtitle: this.content["section-2-card-4-newvalue"],
            subtitle2: this.content["section-2-card-4-oldvalue"],
          },
          {
            title: this.content["section-2-card-5-title"],
            subtitle: this.content["section-2-card-5-newvalue"],
            subtitle2: this.content["section-2-card-5-oldvalue"],
          },
          {
            title: this.content["section-2-card-6-title"],
            subtitle: this.content["section-2-card-6-newvalue"],
            subtitle2: this.content["section-2-card-6-oldvalue"],
          },
        ];
        this.transformationCards = [
          {
            title: this.content["section-2-card-7-title"],
            subtitle: this.content["section-2-card-7-newvalue"],
            subtitle2: this.content["section-2-card-9-oldvalue"],
          },
          {
            title: this.content["section-2-card-8-title"],
            subtitle: this.content["section-2-card-8-newvalue"],
            subtitle2: this.content["section-2-card-10-oldvalue"],
          },
          {
            title: this.content["section-2-card-9-title"],
            subtitle: this.content["section-2-card-9-newvalue"],
            subtitle2: this.content["section-2-card-7-oldvalue"],
          },
          {
            title: this.content["section-2-card-10-title"],
            subtitle: this.content["section-2-card-10-newvalue"],
            subtitle2: this.content["section-2-card-8-oldvalue"],
          },
        ];
        this.images = data.images;
        // this.headerImg = this.content.header[0].image;
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async beforeRouteUpdate(to, from, next) {
    await this.getData();
    next();
  },

  async created() {
    await this.getData();
  },

  components: {
    BottomNavigator,
    PageTitle,
    Navigation,
    Card,
    SurveyResultsCard,
    MeasuringCard,
    GuidingValueCard,
    ListCard,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.leftSide-graph,
.rightSide-graph {
}
.rightSide-graph {
  min-height: 100px;
  justify-content: center;
}
.graph-wrapper {
  display: flex;
  justify-content: space-between;
}
.graph {
  display: inline-block;
  margin-bottom: 0px;
  padding-left: 0px;
}

.bar {
  display: inline-block;
  width: 20px;
  height: 85px;
  border-radius: 2px;
  margin-left: 25px;
  overflow: hidden;
}

.bar-inner {
  width: 100%;
}

.yellow {
  @include bg-color(takeda-yamabuki-yellow);
  height: 10%;
}

.blue {
  @include bg-color(takeda-sorairo-blue);
  height: 55%;
}

.green {
  @include bg-color(takeda-matsu-green);
  height: 35%;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
}

.square {
  width: 15px;
  display: inline-block;
  margin-left: 40px;
}

.circle-color-one {
  background-color: #0C62FB;
}

.circle-color-two {
  background-color: #3D81FC;
;
}

.circle-color-three {
  background-color: #6DA1FD;
;
}

.circle-color-four {
  background-color: #9EC0FD;
;
}

.graph-rating {
  margin-right: 30px;
}

.paragraph {
  padding-left: 35px;
  padding-bottom: 35px;
  @include responsive(phone) {
    padding-left: 10px;
  }
}

.subHeader {
  padding-left: 35px;
  @include color(#4c9bcf);
  font-weight: bold;
  @include responsive(phone) {
    padding-left: 10px;
  }
}
.padding-bottom {
  padding-bottom: 2em;
}
.measuring-card-set {
  padding-left: 1em;
  padding-right: 1em;
  @include responsive(phone) {
    padding-left: 0;
    padding-right: 0;
  }
  @include responsive(tablet) {
    .flex-6 {
      flex: 0 6 50%;
    }
  }
}
.grid {
  display: flex;
  .grid-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .card-wrapper {
      padding: 15px;
      @include responsive(phone) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .grid-cell {
      @include bg-color(takeda-white);
      border-radius: 8px 8px 48px 8px;
      box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
      overflow: hidden;
      position: relative;
      padding: 20px;
    }
  }
}

.grid-content {
  padding-top: 10px;
  padding-bottom: 20px;
}

.subtitle2 {
  @include color(takeda-medium-gray);
  font-size: 16px;
  font-weight: bold;
}

.percentage {
  float: right;
  @include color(takeda-medium-gray);
  font-size: 16px;
  font-weight: bold;
}

.subtitleYear {
  @include color(takeda-medium-gray);
  font-size: 16px;
}

.number {
  @include color(takeda-dark-gray);
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
}

h1 {
  @include color(takeda-dark-gray);
  font-size: 16px;
}

.cardHeading {
  height: 50px;
}

.triangle-img {
  padding-left: 15px;
}

.title {
  @include color(takeda-dark-maroon) font-size: 48px;
}

.section {
  @include padding(8 8 5 8);
  @include responsive(phone) {
    @include padding(4 4 2 4);
    text-align: left;
  }
}

.section {
  :deep(.main-content-container)  {
    @include bg-color(takeda-light-grey);
  }
}

.section-container {
  @include bg-color(takeda-light-grey);
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 4 4 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.theme-container {
  @include relative;
  :deep(.page-title)  {
    .title-container {
      @include bg-color(takeda-dark-maroon);
    }
  }
}
</style>
